export class Media {
	constructor() {
		this.baseUrl = document.querySelector('meta[name="app-url"]').getAttribute("content");
		this.baseUrl = this.baseUrl.replace(/\/?$/, '/');
	}

	previewGenerate() {
		this.els = {
			uploader: document.querySelectorAll('[data-toggle="uploader"]')
		}

		if (!this.els.uploader.length) {
			return;
		}
		
		this.els.uploader.forEach((el) => {
			var files = el.querySelector(".selected-files").value;

			if (files !== "") {
				var filePreviewEl = el.parentNode.querySelector(".file-preview");
				var formData = new FormData();
				formData.append("_token", APP.data.csrf);
				formData.append("ids", files);

				fetch(this.baseUrl + "uploader/get_file_by_ids", {
					method: 'POST',
					body: formData
				})
				.then(response => response.json())
					.then(function (data) {
						
						filePreviewEl.innerHTML = "";

						if (data.length > 0) {
							el.querySelector(".file-amount").innerHTML = APP.uploader.updateFileHtml(data);

							data.forEach(function (item) {
								var thumb = item.type === "image" ? '<img src="' + item.url + '" class="img-fit">' : '<i class="la la-file-text"></i>';
							
								var html =
									'<div class="d-flex justify-content-between align-items-center mt-2 file-preview-item" data-id="' +
									item.id +
									'" title="' +
									item.name +
									"." +
									item.extension +
									'">' +
									'<div class="align-items-center align-self-stretch d-flex justify-content-center thumb">' +
									thumb +
									"</div>" +
									'<div class="col body">' +
									'<h6 class="d-flex">' +
									'<span class="text-truncate title">' +
									item.name +
									"</span>" +
									'<span class="ext flex-shrink-0">.' +
									item.extension +
									"</span>" +
									"</h6>" +
									"<p>" +
									window.extra.bytesToSize(item.file_size) +
									"</p>" +
									"</div>" +
									'<div class="remove">' +
									'<button class="btn btn-sm btn-link remove-attachment" type="button">' +
									'<i class="la la-close"></i>' +
									"</button>" +
									"</div>" +
									"</div>";

								filePreviewEl.insertAdjacentHTML('beforeend', html);
							});
						} else {
							el.querySelector(".file-amount").innerHTML = window.locale.choose_file;
						}
					
					}
				)
				.catch(function (error) {
					console.error('Error:', error);
				});
			}
		});
		
	}
}


window.addEventListener("DOMContentLoaded", function () {
	const mediaClass = new Media;
	
	mediaClass.previewGenerate();

});