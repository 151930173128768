// front

// categorymenu
				
export class CategoryMenu {
	constructor() {
		this.click();
	}
// $("#category-menu-icon, #category-sidebar")
// 			.on("mouseover", function (event) {
// 				$("#hover-category-menu").addClass('active').removeClass('d-none');
// 			})
// 			.on("mouseout", function (event) {
// 				$("#hover-category-menu").addClass('d-none').removeClass('active');
// 			});

	click() {
		this.modifier = 'show';

		this.els = {
			menu: document.querySelector('#click-category-menu'),
			click: document.querySelector('#category-menu-bar'),
			icon: document.querySelector('#category-menu-bar-icon'),
		};

		if (!this.els.menu) {
			return;
		}

		// trigger
		document.body.addEventListener('click', e => {
			
			if(!this.els.click.contains(e.target)){
				this.els.menu.style.display = 'none';
				this.els.icon.classList.remove('show');
			}  
		});
		//

		this.els.menu.style.display = 'none';
		this.els.menu.classList.remove('d-none');

		this.els.click.addEventListener('click', e => {
			this.els.menu.style.display = 'block';

			if (this.els.icon.classList.contains('show')) {
				this.els.icon.classList.remove('show');
			} else {
				this.els.icon.classList.add('show');
			}
		});
		
	}
	has(nodeList, selector) {
		return Array.from(nodeList).filter(e => e.querySelector(selector));
	}
}

new CategoryMenu;

window.addEventListener("DOMContentLoaded", (event) => {
	var userMenu = document.querySelector("#nav-user-info");

	if (userMenu) {
		userMenu.addEventListener("mouseover", function (event) {
			$(".hover-user-top-menu").addClass('active');
		});

		userMenu.addEventListener("mouseout", function (event) {
			$(".hover-user-top-menu").removeClass('active');
		});
	}
});
